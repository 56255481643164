@import "src/styles/helpers";

.paper {
  @include scrollbar;
  min-width: 448px;
  background: var(--white) !important;
  box-shadow: -35px 0px 48.7px 0px rgba(0, 41, 54, 0.10) !important;
  display: grid !important;
  grid-template-rows: auto 1fr;
  padding: 0;

  @include media(mobile) {
    width: 100%;
    min-width: unset;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 24px;
    position: sticky;
    top: 0;
    z-index: 2;
    background: var(--white);
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-500);
    @include ease;

    & > svg {
      width: 24px;
      height: 24px;
    }

    &:hover {
      color: var(--accent-900);
    }
  }

  &__title {
    h2 {
      @include h2;
      color: var(--primary-600);

      @include media(tablet) {
        @include h3;
      }
    }

    p {
      @include bodySmall;
      color: var(--grey);
      margin-top: 8px;
    }
  }
}

.drawer {
  :global {

    .MuiBackdrop-root {
      background: rgba(0, 1, 1, 0.20) !important;
    }
  }

  &__content {
    margin-top: 16px;
    padding: 0 24px 24px;
  }

  .isAbsoluteLoader {
    position: absolute;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
  }

  &__error {
    width: 100%;
    height: 100%;
    display: grid;
    gap: 24px;
    justify-content: center;
    align-content: center;
    justify-items: center;
    text-align: center;

    svg {
      width: 268px;
      height: 264px;
      margin: 0 auto;
    }

    h3 {
      @include h3;
    }

    p {
      @include bodyMedium;
      max-width: 370px;
      color: var(--grey);
    }
  }

  .footer {
    position: sticky;
    bottom: 0;
    background: var(--white);
    padding: 24px;
    border-top: 1px solid var(--secondary-400);
    z-index: 2;
  }
}

.confirmModalButtons {
  display: flex;
  gap: 12px;
}
