@import 'src/styles/helpers';

.description {
  @include bodySmallLight;
  color: var(--dark);
  display: grid;
  gap: 16px;

  &__component p {
    word-break: break-all;
  }
}

.noDataText {
  @include bodySmallLight;
  text-transform: none;
  color: var(--grey);
}
