.drawer {
  .form {
    height: 100%;

    &__fields {
      display: grid;
      grid-auto-rows: min-content;
      gap: 24px;
    }

    &__actions {
      display: flex;
      gap: 16px;
    }

    &__button {
      flex: 1 0 auto;
    }
  }
}
