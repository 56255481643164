@import "src/styles/helpers";

.ufi {
  text-transform: uppercase;
}

.table {
  height: 100%;

  &__cellText {
    @include bodySmall;
    width: 100%;

    &.link {
      height: 100%;
      display: flex;
      align-items: center;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__cellEmpty {
    @include bodySmall;
    color: var(--grey);
    text-align: center;
    width: 100%;
  }

  &__pictograms {
    display: flex;
  }

  &__pictogram {
    background: var(--secondary-200);
    border-radius: 50%;
    padding: 4px;
    width: 32px;
    height: 32px;

    &:not(:first-child) {
      margin-left: -12px;
    }

    &_more {
      display: flex;
      align-items: center;
      justify-content: center;
      @include bodySmall;
    }

    &__icon {
      width: 24px;
      height: 24px;
    }
  }

  :global {
    .MuiDataGrid-cell[data-field=actions] {

      :local {

        .rowDeleteButton {
          color: var(--grey);

          svg {
            width: 24px;
            height: 24px;
          }

          &:hover {
            color: var(--red);
          }
        }

        .sdsMoveButton {
          color: var(--grey);

          svg {
            width: 24px;
            height: 24px;
          }

          &:hover {
            color: var(--dark);
          }
        }
      }
    }
  }
}

.linkItemButton {
  @include bodyExtraSmall(true);
  padding: 10px 12px;
}

.languageFilter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  &__quantity {
    color: var(--grey);
  }
}

.selectedRowsActions {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  gap: 8px;

  &.hidden {
    display: none;
  }

  .rowsActionButton {
    padding: 6px 12px;
    background: transparent !important;
    text-decoration: underline;

    &_delete {
      color: var(--red) !important;

      &:hover {
        color: var(--red-200) !important;
      }
    }
  }
}

.uploadStatus {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 8px;
  }

  &_review:before {
    background: var(--primary-500);
  }

  &_issue:before {
    background: var(--accent-300);
  }

  &_confirmedCA:before {
    background: var(--accent-200);
  }

  &_confirmedSA:before {
    background: var(--accent-500);
  }
}
