@import "src/styles/helpers";

.table {
  flex: 1;
  display: grid;
  align-items: center;

  &__loader {
    margin: 0 auto;
  }

  &_loading {

    .table__content {
      opacity: 0.5;
      cursor: progress;

      > * {
        pointer-events: none;
      }
    }
  }

  &_preloader {
    height: 100%;
  }

  &__content {
    border-radius: 0 !important;

    &:before {
      content: '';
      position: sticky;
      height: 10px;
      margin-bottom: -10px;
      width: 100%;
      background: var(--white);
      top: -24px;
      left: 0;
      pointer-events: none;
      display: block;
      z-index: 3;
    }

    &.searchMode:before {
      height: 34px;
    }
  }

  :global {

    .MuiDataGrid-virtualScroller {
      overflow-y: hidden;
    }

    .MuiDataGrid-overlayWrapperInner {
      height: auto !important;
    }

    .MuiDataGrid-overlayWrapper {
      width: auto !important;
      height: auto !important;
    }

    .MuiDataGrid-scrollbar {
      pointer-events: none !important;
    }

    .MuiDataGrid-root {
      border: none !important;
      border-radius: 0 !important;

      &:before {
        border-left: 1px solid var(--secondary-500) !important;
        top: 0;
        left: 0;
        width: 1px;
        height: 100%;
        position: absolute;
        content: '';
        z-index: 2;
      }

      &:after {
        border-right: 1px solid var(--secondary-500) !important;
        top: 0;
        right: 0;
        width: 1px;
        height: 100%;
        position: absolute;
        content: '';
      }
    }

    .MuiDataGrid-main {
      overflow: unset !important;
    }

    .MuiDataGrid-columnHeaders {
      border-radius: 8px 8px 0 0 !important;
      border: 1px solid var(--secondary-500) !important;
      position: sticky !important;
      top: 0 !important;
      z-index: 4 !important;
    }

    .MuiDataGrid-columnHeader {
      background: var(--secondary-100) !important;
      padding: 0 12px !important;

      &:focus,
      &:focus-within {
        outline: none !important;
      }
    }

    .MuiDataGrid-columnHeaderTitle {
      @include subtitleSmall(true);
      color: var(--grey);
    }

    .MuiDataGrid-columnHeaders {
      color: var(--dark);
      font-family: Inter, sans-serif;
      @include subtitleSmall;
      line-height: 16px !important;
      border: none;

      .MuiDataGrid-columnSeparator {
        display: none;
      }
    }

    .MuiDataGrid-iconButtonContainer {
      margin-left: 4px;
      visibility: visible;
      width: auto;

      .MuiDataGrid-sortIcon {
        opacity: 0.5 !important;
      }
    }

    .MuiDataGrid-columnHeader--sorted {
      .MuiDataGrid-sortIcon {
        opacity: 1 !important;
      }
    }

    .MuiDataGrid-menuOpen {
      svg {
        fill: var(--primary-500);
      }
    }

    .MuiDataGrid-row {
      border-bottom: 1px solid var(--secondary-400) !important;

      &.Mui-selected {
        background: transparent !important;
      }

      &.Mui-hovered,
      &:hover {
        background: var(--secondary-100) !important;
      }

      & > div {
        border-left: none;
        border-top: none;
      }

      &:last-of-type {
        border-bottom: 1px solid var(--secondary-500) !important;
      }
    }

    .MuiDataGrid-cell {
      display: flex;
      align-items: center;
      padding: 16px 12px !important;
      color: var(--dark);
      font-family: Inter, sans-serif;
      @include bodySmall;
      border: none !important;
      hyphens: auto !important;
      word-break: break-word !important;

      &:global {
        &:has(.fullSizeCell) {
          padding: 0 !important;
        }

        &:has(textarea) {

          &:hover {
            background: var(--bg-main);
          }
        }

        &:has(textarea:focus) {
          background: var(--bg-main);
          outline: none;
        }

        &:has(.translationsTimestamp) {
          background: var(--secondary-100);
        }
      }

      &:focus {
        outline: none !important;
      }

      &[data-field="actions"] {
        padding: 0 !important;

        div,
        button {
          border-radius: 0;
          width: 100% !important;
          height: 100% !important;
        }
      }
    }

    .MuiDataGrid-cellContent {
      word-break: break-word;
      white-space: break-spaces;
    }

    .MuiDataGrid-footerContainer {
      border: none;
    }

    button[role="menuitem"] {
      padding: 0;

      svg {
        height: 18px;
        width: 18px;
        color: var(--secondary-800);
      }

      &:hover {
        background: transparent !important;

        svg {
          color: var(--dark);
        }
      }
    }

    .MuiDataGrid-menuList {
      color: var(--grey);
      text-overflow: ellipsis;

      font-family: Inter, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      overflow: hidden;


    }
  }

  &_selectMode {

    :global {
      .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderCheckbox) {
        pointer-events: none !important;

        .MuiDataGrid-columnHeaderTitleContainer {
          opacity: 0 !important;
        }
      }
    }
  }
}

.sortArrowSVG {
  height: 20px;
  width: 20px;

  &_desc {
    transform: rotate(180deg);
  }
}

.filteredSVG {
  height: 20px;
  width: 20px;

  rect {
    height: 20px;
    width: 20px
  }
}

.menuIcon {
  width: 12px;
  height: 13px;
  color: var(--dark);
}

.pagination {

  ul {
    justify-content: center;
    gap: 8px;

    li {
      &:last-of-type {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__item {
    margin: 0 !important;
    width: 32px !important;
    height: 32px !important;
    padding: 10px !important;
    border-radius: 4px !important;
    color: var(--dark) !important;
    text-align: center !important;
    font-family: Inter, sans-serif !important;
    @include bodySmall(true);
    border: none !important;

    &:not(:global .MuiPaginationItem-ellipsis, :global .Mui-selected):hover {
      background: var(--accent-50) !important;
      color: var(--accent-900) !important;
    }

    &:global {
      &.Mui-selected {
        background: var(--accent-900) !important;
      }

      &.MuiPaginationItem-previousNext, &.MuiPaginationItem-firstLast {
        transition: all .3s ease !important;
        border: 1px solid var(--secondary-600) !important;

        &:hover {
          border-color: var(--accent-100) !important;
        }
      }
    }

    svg {
      height: 12px;
      width: 14px;
    }
  }

  :global {
    .Mui-selected {
      color: var(--white) !important;
    }

    .MuiPaginationItem-ellipsis {
      border: none !important;
    }
  }
}

.clippedCell {
  word-break: break-word;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.filter {
  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 4px;
  }

  &__title {
    color: var(--grey);
    @include bodySmall;


    &::first-letter {
      text-transform: uppercase;
    }
  }

  &__inputGroup {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }

  &__search {
    position: relative;
  }

  &__input {
    height: 36px;
    width: 100%;

    :global {
      .MuiInputBase-root {
        height: 36px;

        .MuiInputBase-input {
          padding-right: 0 !important;
          padding-left: 10px !important;
          @include bodySmall;


        }
      }


      .MuiFormLabel-root:not(.MuiInputLabel-shrink) {
        transform: translate(10px, 8px) scale(1);
      }

      .MuiButtonBase-root {
        padding: 4px !important;
      }
    }

    &_search {
      height: 40px;

      &:before {
        content: '';
      }

      :global {
        .MuiInputBase-root {
          height: 40px;

          .MuiInputBase-input {
            padding-top: 6px;
            padding-bottom: 6px;
            padding-left: 36px !important;
            @include bodySmall;
          }
        }
      }
    }
  }

  &__searchIcon {
    position: absolute;
    left: 12px;
    top: 9px;
    height: 15px;
    width: 15px;
    color: var(--grey);
    z-index: 2;
  }

  &__list {
    padding-left: 11px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    @include bodySmall;
    color: var(--dark);
    max-height: 150px;
    overflow-y: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;


    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__search {
    height: 32px;

    :global {
      .MuiInputBase-root {
        height: 32px;
      }

      .MuiFormLabel-root:not(.MuiInputLabel-shrink) {
        transform: translate(14px, 5px) scale(1);
      }
    }
  }

  &__clearBtn {
    width: fit-content;
    @include bodySmall;
    color: var(--primary-500);


    &:hover {
      color: var(--primary-300);
    }

    &:focus {
      color: var(--primary-600);
    }

    &:active {
      color: var(--primary-800);
    }

    &_small {
      position: absolute;
      top: 9px;
      right: 12px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 2;

      &:hover,
      &:focus {
        background-color: var(--secondary-100);
      }

      &:active {
        background-color: var(--secondary-200);
      }
    }
  }

  &__clearIcon {
    height: 16px;
    width: 16px;
    color: var(--grey);
  }
}

.columnMenuFilterItem {
  font-size: 40px;
}

.arrow {
  width: 12px;
  height: 12px;
  flex-shrink: 0;

  &_prev {
    transform: rotate(90deg);
  }

  &_next {
    transform: rotate(-90deg);
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  background: var(--secondary-100);
  padding: 11px 16px;
  border: 1px solid var(--secondary-500);
  border-top: none;
  border-radius: 0 0 8px 8px;

  @include media(mobile) {
    justify-content: center;
  }
}

.perPage {
  @include bodySmallLight;
  color: var(--primary-500);
  display: flex;
  align-items: center;
  gap: 8px;

  @include media(mobile) {
    display: none;
  }

  &__selectContent {
    display: grid !important;
    border: 1px solid var(--secondary-500) !important;
    border-bottom: none !important;
    border-radius: 8px 8px 0 0 !important;
    width: 70px !important;
  }

  &__item {
    padding: 12px;
    background: var(--secondary-50);

    &:not(.perPage__item_active) {

      &:hover {
        background: var(--secondary-200);
      }

      &:first-child {
        border-radius: 8px 8px 0 0;
      }

      &:not(:last-child) {
        border-bottom: 1px solid var(--secondary-500);
      }
    }

    &_active {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      border: 1px solid var(--secondary-500);
      border-radius: 8px;
      @include ease;
      width: 70px;

      [aria-describedby="simple-popover"] & {
        border-radius: 0 0 8px 8px;
      }

      &:hover {
        color: var(--accent-700);
        border-color: var(--accent-700);
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.header {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  @include media(mobile) {
    gap: 8px;
  }

  &_withCustom {
    @include media(mobile) {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
      gap: 12px;

      button {
        width: 100%;
      }
    }
  }
}

.searchWithBarcode {
  display: flex;
  align-items: center;
  gap: 8px;
}

.search {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  max-width: 560px;
  width: 100%;
  border-radius: 8px;

  @include media(mobile) {
    max-width: none;
  }

  &__types {
    display: grid !important;
    min-width: 120px !important;
    border-radius: 8px !important;
  }

  &__type {
    padding: 12px 16px;
    background: var(--secondary-50);
    @include bodySmall;
    color: var(--primary-600);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background: var(--secondary-200);
    }

    &:first-child {
      border-radius: 8px 8px 0 0;
    }

    &:last-child {
      border-radius: 0 0 8px 8px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--secondary-400);
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__input {
    height: 42px;
    padding: 4px 16px 4px 40px;
    border: 1px solid var(--secondary-500);
    border-radius: 0 8px 8px 0;
    @include bodyMedium;
    color: var(--primary-500);
    width: 100%;
    @include ease;

    &:focus, &:active {
      outline: none;
      border-color: var(--primary-400);

      &::placeholder {
        color: var(--primary-500);
      }

      ~ svg {
        color: var(--primary-500);
      }
    }

    &::placeholder {
      font-family: Inter, sans-serif;
      color: var(--grey);
      @include bodySmall;
    }

    &:not(:placeholder-shown) {

      ~ svg {
        color: var(--primary-500);
      }
    }
  }

  &__inputWrap {
    position: relative;

    svg {
      position: absolute;
      width: 16px;
      height: 16px;
      top: calc(50% - 8px);
      left: 16px;
      color: var(--grey);
    }

    .search__input:focus::placeholder {
      opacity: 0;
    }

    &:hover {

      .search__input {
        border-color: var(--primary-400);

        &::placeholder {
          color: var(--primary-500);
        }

        ~ svg {
          color: var(--primary-500);
        }
      }
    }
  }
}

.search__activeType {
  height: 42px;
  border: 1px solid var(--secondary-500);
  border-right: none;
  border-radius: 8px 0 0 8px !important;

  svg {
    width: 18px;
    height: 18px;
    color: var(--primary-500);
    transition: transform .3s ease;
  }

  &:hover svg {
    color: inherit;
  }

  [aria-describedby="simple-popover"] & {

    svg {
      transform: rotate(-180deg);
    }
  }
}

.actions {

  &_withCustom {
    display: grid;
    grid-template-columns: auto auto;
    gap: 12px;
  }

  @include media(mobile) {
    grid-template-columns: 1fr 1fr;

    button {
      width: 100%;
    }
  }
}

.tooltip {
  background: var(--dark) !important;
  padding: 4px 8px !important;
  @include bodySmallLight(true);

  &__arrow:before {
    color: var(--dark) !important;
  }
}

.noResults {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  color: var(--dark);
  @include bodyMedium;
  font-family: Inter, sans-serif;
}

.checkbox {
  width: auto !important;
}

.filtersCounter {
  width: 18px;
  height: 18px;
  background: var(--dark);
  border-radius: 50%;
  @include bodySmall;
  color: var(--primary-50);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
}

.filtersButton {

  @include media(mobile) {
    padding: 12px;
  }

  > div {

    @include media(mobile) {
      margin-right: 0 !important;
    }
  }
}
