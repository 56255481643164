@import "src/styles/helpers";

.page {
  padding: 20px;
  background: var(--secondary-400);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.section {
  background: var(--secondary-50);
  padding: 18px;
  border-radius: 12px;

  &.wide {
    grid-column: 1 / 3;
  }
}

.content {
  display: grid;
  gap: 12px;
  margin-top: 24px;
}

.h1 {
  @include h1;
}

.h2 {
  @include h2;
}

.h3 {
  @include h3;
}

.h4 {
  @include h4;
}

.subtitle {
  @include subtitle;
}

.subtitleSmall {
  @include subtitleSmall;
}

.bodyMediumLight {
  @include bodyMediumLight;
}

.bodyMedium {
  @include bodyMedium;
}

.bodySmallLight {
  @include bodySmallLight;
}

.bodySmall {
  @include bodySmall;
}

.bodyExtraSmall {
  @include bodyExtraSmall;
}

.linkTextSmall {
    @include linkTextSmall;
}

.linkTextExtraSmall {
  @include linkTextExtraSmall;
}

.buttonFont {
  @include buttonFont;
}

.drawer {
  &__content {
    height: 100%;
    display: grid;
    grid-template-rows: 1fr auto;
  }

  &__actions {
   display: flex;
    justify-content: flex-end;
  }

  &__button {
  
  }
}
