@import "src/styles/helpers";

.notification {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: flex-start;
  gap: 8px;
  background-color: var(--white);
  box-shadow: 0px 12px 22.1px 0px #05212912;
  padding: 16px;
  border-radius: 8px;
  width: 356px;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--grey);

    & > svg {
      width: 20px;
      height: 20px;
    }
  }

  &__content {
    display: grid;
    grid-template-rows: auto 1fr;
  }

  &__title {
    @include subtitle;
    color: var(--dark);
  }

  &__body {
    @include bodySmall;
    color: var(--grey);
    margin-top: 8px;
    word-break: break-word;
    width: 100%;
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--grey);

    & > svg {
      width: 16px;
      height: 16px;
    }

    &:hover {
      color: var(--primary-500);
    }
  }
}

.success  {
  border-top: 4px solid var(--green);
}

.warning {
  border-top: 4px solid var(--yellow);
}

.error {
  border-top: 4px solid var(--red);
}
