@import "src/styles/helpers";

.tableContainer {
  display: grid;
  grid-template-columns: 1fr;
}

.table {
  min-width: 300px !important;

  :global {
    .MuiTableHead-root {
      border-bottom: 1px solid var(--secondary-400);
      background: var(--secondary-100);

      .MuiTableCell-head {
        @include subtitleSmall;
        color: var(--grey);

        &:first-child {
          border-radius: 8px 0 0 0;
        }

        &:last-child {
          border-radius: 0 8px 0 0;
        }
      }
    }

    .MuiTableBody-root {
      .MuiTableCell-body {
        color: var(--dark);
        height: 64px;
      }
    }

    .MuiTableRow-root {
      &:not(:last-child) {
        border-bottom: 1px solid var(--secondary-400);
      }

      &:hover {
        background: var(--secondary-100);
      }
    }

    .MuiTableCell-root {
      @include bodySmall;
      font-family: Inter, sans-serif;
      padding: 16px;
      border: none;
    }
  }

  &__row {
    &_hover {
      &:hover {
        @include ease;
        background: var(--secondary-100);

        .table__cell_showOnHover {
          opacity: 1;
        }
      }

      .table__cell_showOnHover {
        opacity: 0;
      }
    }

    &_selected {
      background: var(--primary-100);

      &:hover {
        background: var(--primary-100) !important;
      }

      .table__cell_showOnHover {
        opacity: 1 !important;
      }
    }
  }

  &__headerCellContent {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    gap: 8px;
    width: min-content;
    white-space: nowrap;
  }

  &__bodyCellContent {
    display: grid;
    align-items: center;
    gap: 8px;
  }

  &__cell {
    &_expander {
      display: flex;
      width: min-content !important;
      max-width: min-content !important;
      padding-right: 0 !important;
    }

    &_actions {
      width: 0;
    }

    &_smallPadding {
      padding: 16px 8px !important;
    }
  }
}

.sortArrowIcon {
  height: 20px;
  width: 20px;
  color: var(--grey);
}

.expandArrowIcon {
  height: 20px;
  width: 20px;
  color: var(--secondary-800);

  &_expanded {
    color: var(--primary-600);
  }
}

.noResults {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  color: var(--dark);
  @include bodyMedium;
  font-family: Inter, sans-serif;
}
