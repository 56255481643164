@import "src/styles/helpers";

.info {
    display: grid;
    gap: 16px;
}

.title {
  @include h1;
  color: var(--primary-600);

  @include media(mobile) {
    @include h2;
  }
}

.subtitle {
  @include bodyMediumLight;
  color: var(--primary-400);
}

.form {
  margin-top: 48px;

  &__fields {
    display: grid;
    gap: 24px;
  }

  &__button {
    min-width: 100%;
    margin-top: 23px;
  }
}

.backLink {
  @include buttonFont;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 21px;
  color: var(--primary-600);
}

.arrowBackIcon {
  width: 18px;
  height: 18px;

  svg,
  img {
    width: 100%;
    height: 100%;
  }
}
