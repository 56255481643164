@import 'src/styles/helpers';

.iconLabel {
  @include bodySmall;
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    width: 16px;
    height: 16px;
  }
}