@import "src/styles/helpers";

.inputContainer {
  position: relative;

  .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 18px;
    color: var(--grey);

    svg, img {
      width: 100%;
      height: 100%;
    }
  }

  &:has(:global .Mui-focused) {
    .icon {
      color: var(--primary-600);
    }
  }

  &:has(:global .field-error) {
    .icon {
      color: var(--red);
    }
  }
}

.input {
  width: 100%;

  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  &.disabled {

    + .icon {
      color: var(--grey);
    }
  }

  &:hover {
    :global .MuiInputLabel-root:not(.Mui-focused):not(.Mui-disabled) {
      color: var(--primary-500) !important;
    }
  }

  :global {

    .MuiInputLabel-root {
      color: var(--grey) !important;
      @include bodyMediumLight($important: true);
      font-family: Inter, sans-serif !important;
      letter-spacing: normal !important;
      transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      padding: 0 !important;

      &.MuiInputLabel-shrink {
        @include bodySmall($important: true);
        transform: translate(14px, -8px) scale(1) !important;
        color: var(--dark) !important;

        .MuiInputLabel-asterisk {
          color: var(--red) !important;
        }
      }

      &.Mui-disabled {
        color: var(--grey) !important;

        &:hover {
          color: var(--grey) !important;
        }

        &.MuiFormLabel-filled {
          color: var(--dark) !important;

          &:hover {
            color: var(--dark) !important;
          }
        }
      }

      &.Mui-focused {
        color: var(--dark) !important;
      }

      &:not(.MuiInputLabel-shrink) {
        left: 3px !important;
        padding-top: 2px;
      }

      &.Mui-error {
        color: var(--grey) !important;

        &.MuiInputLabel-shrink {
          color: var(--dark) !important;
        }
      }
    }

    .MuiInputBase-root {
      color: var(--dark) !important;

      textarea.MuiInputBase-input {
        padding: 0 !important;
      }

      .MuiInputBase-input {
        padding: 14px 28px 14px 18px !important;

        &.Mui-disabled {
          color: var(--grey) !important;
          -webkit-text-fill-color: inherit !important;
        }
      }

      fieldset {
        border-color: var(--secondary-500) !important;
        border-radius: 8px !important;

        legend {
          font-family: Inter, sans-serif !important;
          @include bodySmall($important: true);
          letter-spacing: normal !important;
        }
      }

      &:not(.Mui-disabled):not(.Mui-error):not(.Mui-focused) {

        &:hover {

          fieldset {
            border-color: var(--primary-400) !important;
          }
        }
      }

      &.Mui-disabled {
        background: var(--secondary-100);
        fieldset {
          border-color: var(--secondary-500) !important;
        }

        &:hover {
          fieldset {
            border-color: var(--secondary-500) !important;
          }
        }
      }

      &:focus-within,
      &:focus-visible {

        fieldset {
          border: 1px solid var(--primary-500) !important
        }
      }

      &.Mui-error {

        fieldset {
          border-color: var(--red) !important;
        }
      }
    }
  }
}

.error {
  color: var(--red);
  margin-top: 4px;
  @include bodySmall
}

.infoText {
  color: var(--grey);
  margin-top: 4px;
  @include bodySmall
}
