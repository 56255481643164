@import "src/styles/helpers";

.content {
  display: grid;
  gap: 24px;
}

.chip {
  background: var(--secondary-200);
  border-radius: 4px;
  padding: 6px 8px;
  color: var(--dark);
  @include bodySmall;
}

.footer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  button {
    width: 100%;
  }
}

.uploadedRecord {

  > * {
    pointer-events: none;
  }

  &__input {

    :global {

      .MuiInputBase-root {

        .MuiInputBase-input.Mui-disabled {
          color: var(--dark) !important;
          text-decoration-color: var(--dark) !important;
          text-decoration: underline !important;
        }
      }
    }
  }

  &:hover {
    text-decoration: none;

    .uploadedRecord__input {

      :global {

        .MuiInputBase-root {

          .MuiInputBase-input.Mui-disabled {
            text-decoration: none !important;
          }
        }
      }
    }
  }
}

.addSDSButton {
  width: 100%;
}
