@import "src/styles/helpers";

.scanBarcode {
  :global {
    .BarcodeScanner {
      height: 200px;
      width: 100%;
    }
  }

  &__modal {
    padding-top: 40px;

    @include media(mobile) {
      max-width: 90vw;
    }
  }
}
