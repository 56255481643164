@import 'src/styles/helpers';

.wrapper {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 24px;

  @include media(tabletPortrait) {
    grid-template-columns: 1fr;
  }

  @include media(mobile) {
    grid-template-columns: 1fr;
  }
}

.details {
  &Content {
    display: grid;
    gap: 24px;
    padding: 24px 16px;

    &_edit {
      .detailsContent__item {
        align-items: center;
      }
    }

    &__item {
      display: grid;
      grid-template-columns: minmax(150px, min-content) 2.5fr;
      gap: 16px;
      color: var(--dark);

      @include media(mobile) {
        grid-template-columns: 1fr 2fr;
      }

      &Label {
        @include bodySmall;
      }

      &Value {
        @include bodySmallLight;
        display: flex;
        align-items: center;

        mark {
          @include subtitleSmall;
          display: block;
        }
      }

      .identifiers {
        display: grid;
        grid-template-columns: 1fr;
        gap: 12px;

        .detailsContent__itemValue {
          display: block;
        }
      }
    }
  }

  .card__actions {
    @include media(mobile) {
      display: none;
    }
  }
}

.items {
  &Content {
    display: grid;

    &__item {
      display: grid;
      align-items: center;
      gap: 8px;
      grid-template-columns: 1fr auto;
      padding: 16px;

      &Label {
        @include linkTextSmall;
        color: var(--dark);
      }

      &Action {
        width: 20px !important;
        height: 20px !important;
        padding-left: 0 !important;
        padding-right: 0 !important;

        svg,
        img {
          color: var(--red);
          width: 100%;
          height: 100%;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid var(--secondary-500);
      }
    }

    &__noItems {
      display: grid;
      place-items: center;
      gap: 24px;
      padding: 85.5px 16px;

      svg,
      img {
        width: 165px;
        height: 149px;
      }

      &Text {
        @include bodyMedium;
        text-align: center;
        color: var(--grey);
      }
    }
  }
}

.card {
  height: min-content;
  border-radius: 8px;
  border: 1px solid var(--secondary-500);

  @include media(tabletLandscape) {
    position: sticky;
    top: 0;
  }

  @include media(desktopAll) {
    position: sticky;
    top: 0;
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid var(--secondary-300);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: var(--secondary-100);
  }

  &__title {
    @include h4;
    color: var(--dark);
  }

  &__actions {
    display: flex;
    gap: 12px;

    &Buttons {
      display: flex;
      gap: 12px;
    }
  }

  &__content {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &__footer {
    display: grid;
    justify-content: flex-end;
    padding: 16px;
    border-top: 1px solid var(--secondary-300);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background: var(--secondary-100);

    &Actions {
      display: flex;
      gap: 12px;
    }
  }
}

.pictogram {
  align-items: center;

  @include media(mobile) {
    align-items: normal;
  }

  .detailsContent__itemValue {
    display: grid;
    grid-template-columns: repeat(auto-fill, 96px);
    justify-content: left;
    gap: 8px;

    @include media(mobile) {
      grid-template-columns: repeat(auto-fill, 71px);
    }
  }

  &__icon {
    width: 96px;
    height: 96px;

    @include media(mobile) {
      width: 71px;
      height: 71px;
    }
  }

  @include media(mobile) {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.version {
  gap: 4px;
}

.signalWord {
  @include subtitleSmall;
  text-transform: uppercase;
}

.productList {
  display: grid;
  gap: 12px;
}

.noDataText {
  @include bodySmallLight;
  text-transform: none;
  color: var(--grey);
}

.description {
  @include bodySmallLight;
  color: var(--dark);
  display: grid;
  gap: 16px;
}

.actionIconPopover {
  display: grid;
  align-items: center;

  &__icon {
    width: 18px;
    height: 18px;

    svg,
    img {
      width: 100%;
      height: 100%;
    }
  }

  &__paper {
    border-radius: 8px !important;
  }
}

.reportIssueButton {
  padding: 14px 16px !important;
}

.details__actionsButtons {
  display: none;

  @include media(mobile) {
    display: flex;
    flex-direction: column-reverse;
    gap: 12px;
    padding: 8px 0 16px 0;
    margin: -16px;
    width: 100%;
    background-color: var(--white);
    position: sticky;
    left: 0;
    bottom: -32px;

    button {
      width: 100%;
    }
  }
}

.uploadNewVersion {
  cursor: pointer;
}

.dateRangePicker {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 4px;
}

.datepicker {
  box-shadow: var(--box-shadow) !important;
  z-index: 1 !important;

  :global {
    .rs-calendar:first-child {
      border-right: none !important;
    }

    .rs-btn-primary {
      height: 32px !important;
      padding: 0 12px !important;
      border-radius: 8px !important;
      @include bodyExtraSmall(true);
    }

    .rs-calendar-table-cell-selected {
      .rs-calendar-table-cell-day {
        @include subtitle(true);
      }
    }

    .rs-calendar-table-cell-content {
      @include bodyMediumLight(true);
    }

    .rs-calendar-table-header-cell-content {
      @include subtitleSmall(true);
      color: var(--grey) !important;
    }

    .rs-btn-subtle:hover, .rs-btn-subtle:focus {
      background: var(--primary-100) !important;
    }

    .rs-calendar-header-title {
      @include h4(true);
      color: var(--dark) !important;

      &:hover, &:focus {
        background: transparent !important;
        color: var(--accent-900) !important;
      }
    }

    .rs-calendar-header {
      padding-bottom: 10px !important;
      border-bottom: 1px solid var(--secondary-600) !important;
    }

    .rs-picker-toolbar {
      border-top-color: var(--secondary-600) !important;
    }

    .rs-btn-icon.rs-btn-xs {
      width: 28px !important;
      height: 28px !important;
      color: var(--dark) !important;
    }

    .rs-calendar-body {
      padding-bottom: 0 !important;
    }
  }

  &__input {
    outline-color: var(--accent-900) !important;

    :global {

      .rs-input-group {
        display: flex !important;
        align-items: center !important;
      
        &:not(.rs-input-group-disabled).rs-input-group-focus, .rs-input-group:focus-within {
          outline: 1px solid var(--accent-900) !important;
        }
      }
    }

    input {
      height: 51px !important;
      padding: 14px 18px !important;
      font-family: Inter, sans-serif;
      @include bodyMediumLight(true);
    }

    .calendarIcon {
      width: 16px;
      height: 16px;
    }
  }
}

.descriptionInputs {
  display: grid;
  gap: 16px;
}

.productsBlock {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__content {
    display: grid;
    gap: 8px;
  }

  &__item {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 8px;
  }

  &__button {
    padding: 0 !important;
  }

  &__deleteButton {
    align-self: flex-start;
    padding: 0 !important;
    margin-top: 16px !important;
    color: var(--red-400) !important;

    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.language {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 8px;
}

.confirmModalButtons {
  display: flex;
  gap: 12px;
}
