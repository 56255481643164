@import "src/styles/helpers";

.pagination {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 11px;
  background: var(--secondary-100);
  border-radius: inherit;
}

.pagesInfo {
  @include bodySmallLight;
  color: var(--primary-500);
  display: flex;
  align-items: center;
  gap: 8px;

  &__select {
    display: grid !important;
    border: 1px solid var(--secondary-500) !important;
    border-bottom: none !important;
    border-radius: 8px 8px 0 0 !important;
    width: 70px !important;

    &Item {
      padding: 12px;
      background: var(--secondary-50);

      &:not(.pagesInfo__selectItem_active) {

        &:hover {
          background: var(--secondary-200);
        }

        &:first-child {
          border-radius: 8px 8px 0 0;
        }

        &:not(:last-child) {
          border-bottom: 1px solid var(--secondary-500);
        }
      }

      &_active {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        border: 1px solid var(--secondary-500);
        border-radius: 8px;
        @include ease;
        width: 70px;

        [aria-describedby="simple-popover"] & {
          border-radius: 0 0 8px 8px;
        }

        &:hover {
          color: var(--accent-700);
          border-color: var(--accent-700);
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.steps {
  ul {
    justify-content: center;
    gap: 8px;

    li {
      &:last-of-type {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__item {
    margin: 0 !important;
    width: 32px !important;
    height: 32px !important;
    padding: 10px !important;
    border-radius: 4px !important;
    color: var(--dark) !important;
    text-align: center !important;
    font-family: Inter, sans-serif !important;
    @include bodySmall(true);
    border: none !important;

    &:not(:global .MuiPaginationItem-ellipsis, :global .Mui-selected):hover {
      background: var(--accent-50) !important;
      color: var(--accent-900) !important;
    }

    &:global {
      &.Mui-selected {
        background: var(--accent-900) !important;
      }

      &.MuiPaginationItem-previousNext, &.MuiPaginationItem-firstLast {
        transition: all .3s ease !important;
        border: 1px solid var(--secondary-600) !important;

        &:hover {
          border-color: var(--accent-100) !important;
        }
      }
    }

    svg {
      height: 12px;
      width: 14px;
    }
  }

  :global {
    .Mui-selected {
      color: var(--white) !important;
    }

    .MuiPaginationItem-ellipsis {
      border: none !important;
    }
  }
}
