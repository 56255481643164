@import 'src/styles/helpers';

.drawer {
  .form {
    height: 100%;

    &__fields {
      display: grid;
      grid-auto-rows: min-content;
      gap: 24px;

      .itemsRestrictedLabel {
        font-family: Inter, sans-serif;
        @include bodyMedium;

        display: inline-flex;
        align-items: center;
        gap: 4px;

        span {
          display: inline-flex;
        }
      }
    }

    &__actions {
      display: flex;
      gap: 16px;
    }

    &__button {
      flex: 1 0 auto;
    }
  }
}

.tooltip {
  background: var(--dark) !important;
  padding: 4px 8px !important;
  @include bodySmallLight(true);

  &__arrow:before {
    color: var(--dark) !important;
  }
}

.infoIcon {
  height: 18px;
  width: 18px;

  &:hover {
    color: var(--accent-800);
  }
}
