@import "src/styles/helpers";

.sidebar {
  background: var(--primary-800);
  padding: 24px 0;
  min-width: 220px;
  display: grid;
  align-items: flex-start;
  grid-template-rows: auto 1fr auto;

  @include media(tabletPortrait) {
    min-height: 100%;
  }

  @include media(mobile) {
    min-height: 100%;
  }

  &.hidden {
    @include media(mobile) {
      display: none;
    }
  }

  &.minimized {
    width: auto;
    min-width: 0;

    .header {
      display: block;
      padding: 0;
    }

    .toggleSidebarButton {
      margin: 0 auto;
    }
  }
}

.header {
  padding: 0 16px 0 24px;
  display: grid;
  grid-template-columns: auto auto;
  gap: 24px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 84px;

  .toggleSidebarButton {
    background: var(--primary-700);
    padding: 8px !important;
    color: var(--white);

    &:hover {
      background: var(--primary-600);
    }

    &:active {
      background: var(--primary-500);
    }
  }
}

.logo {
  width: 83px;
  height: 28px;
}

.menu {
  display: grid;
  gap: 24px;
}

.link {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--secondary-900);
  padding: 16px 24px;
  @include bodyMedium;
  @include ease;

  &:hover {
    color: var(--secondary-200);
  }

  &.active {
    color: var(--secondary-100);
    position: relative;
    background: var(--primary-700);

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 4px;
      background: var(--accent-500);
    }
  }
}

.icon {
  width: 20px;
  height: 20px;
}

.minimizedMenuIcon {
  transform: rotate(180deg)
}

.supportCenter {
  align-self: flex-end;
  margin-top: 32px;

  &__button {
    @include bodyMedium(true);
    @include ease;
    justify-content: flex-end !important;
    padding: 16px 24px !important;
    width: 100% !important;
    border-radius: 0 !important;
    color: var(--secondary-900);
    gap: 8px;

    & > div {
      width: 20px !important;
      height: 20px !important;
      margin: 0 !important;
    }

    &:hover {
      color: var(--secondary-200);
    }

    &.active {
      color: var(--secondary-100);
      position: relative;
      background: var(--primary-700);

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 4px;
        background: var(--accent-500);
      }
    }
  }
}
