@import 'src/styles/helpers';

.page {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 24px;

  @include media(mobile) {
    gap: 16px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media(mobile) {
    flex-direction: column;
    align-items: normal;
    gap: 16px;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }

  &__title {
    display: none;

    @include media(mobile) {
      @include h4;
      display: block;
    }
  }

  &__total {
    @include h4;
    color: var(--primary-600);

    @include media(mobile) {
      @include subtitleSmall;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;

    & > button {
      width: 100%;
    }
  }
}

.table {
  &__actions {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__expandCell {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__buttonTitle {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1 0 auto;
    max-width: 430px;

    @include media(desktop) {
      max-width: 210px;
    }

    @include media(desktopSmall) {
      max-width: 150px;
    }

    @include media(tabletLandscape) {
      max-width: 190px;
    }

    @include media(tabletPortrait) {
      max-width: 110px;
    }

    @include media(mobile) {
      max-width: 95px;
    }

    &Text {
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }

  &__cellText {
    text-align: center;
  }

  &__moveItem {
    justify-self: self-end;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.wrapper {
  display: grid;
  gap: 24px;

  @include media(mobile) {
    gap: 16px;
  }
}

.content {
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  align-items: flex-start;
  gap: 24px;

  @include media(mobile) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    gap: 16px;
  }
}

.main {
  border-radius: 8px;
  border: 1px solid var(--secondary-400);

  &_loading {
    opacity: 0.5;
    cursor: progress;

    > * {
      pointer-events: none;
    }
  }
}

.expandIcon {
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  color: var(--primary-600);
}

.addNewSubWarehouse {
  &__icon {
    height: 20px;
    width: 20px;
  }
  
  &:hover {
    .addNewSubWarehouse__icon {
      color: var(--accent-900);
    }
  }
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 24px;

  &__text {
    @include bodyMedium;
    color: var(--grey);
  }
}

.tooltip {
  background: var(--dark) !important;
  padding: 4px 8px !important;
  @include bodySmallLight(true);

  &__arrow:before {
    color: var(--dark) !important;
  }
}

.expandArrowIcon {
  height: 20px;
  width: 20px;
  color: var(--secondary-800);

  &_expanded {
    color: var(--primary-600);
  }
}
