@import "src/styles/helpers";

.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.counter {
  display: flex;
  min-width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  background: var(--primary-400);
  color: var(--white);
  @include bodySmall;
  border-radius: 12px;
  padding: 4px;
}

.paper {
  width: 448px;

  @include media(mobile) {
    max-width: 448px;
    width: 100%;
  }
}

.content {
  padding: 0 !important;
  margin-top: 0 !important;
}

.noContent {
  text-align: center;
  min-height: calc(100% - 80px); // exclude drawer header height
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 16px;

  &__image {
    width: 164px;
    height: 164px;
    border-radius: 50%;
    background: var(--secondary-200);
    color: var(--primary-300);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

    svg {
      width: 84px;
      height: 84px;
    }
  }

  &__title {
    @include h3;
    margin: 24px 0 12px;
    color: var(--primary-600);
  }

  &__text {
    @include bodyMedium;
    color: var(--grey);
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;

  &__icon {
    margin: 8px;
  }
}
