@import "src/styles/helpers";

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.button {
  @include media(mobile) {
    width: 100%;
  }
}
